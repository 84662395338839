import React from "react"
import { useLocation } from "react-router-dom";


/** Loader */
export const SiteLoader = ({ text }) => {
    const { pathname } = useLocation();
    
    if (pathname === "/") {
        return null;
    }
    return (
        <div
            style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                width: "calc(100% - 1rem)",
            }}
        >
            <div className="Loader">
                <div className="d-block">
                    <div className="spinner-border" role="status"></div>
                </div>
            </div>
            <p style={{ fontSize: "1.5rem", lineHeight: "1.1" }}>
                {text !== undefined ? text : ("Loading...")}
            </p>
        </div>
    );
};