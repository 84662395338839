import React, { Suspense, lazy } from "react";
// import Loading from "./Components/Loading";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { SiteLoader } from "./helpers/utils.js";

// import Header from './Components/Header';
// import Footer from './Components/Footer';

import '../src/Assets/fonts/GT_Walsheim/stylesheet.css';
import '../src/Assets/css/bootstrap.min.css';
import '../src/Assets/css/common.css';

const ViewIndex = lazy(() => import("./Pages/Index"));
const Home = lazy(() => import("./Pages/Home"));
const About = lazy(() => import("./Pages/About"));
const ContactUs = lazy(() => import("./Pages/ContactUs"));
const Services = lazy(() => import("./Pages/Services"));
const Privacy = lazy(() => import("./Pages/Privacy"));
const TermsConditions = lazy(() => import("./Pages/TermsConditions"));
const CookiePolicy = lazy(() => import("./Pages/CookiePolicy"));


// import Home from './Pages/Home';
// import About from './Pages/About';
// import ContactUs from './Pages/ContactUs';
// import Privacy from './Pages/Privacy';
// import Services from './Pages/Services';



// import ViewIndex from './Pages/Index'



function App() {
  return (
    
      
        

        <main className='d-flex flex-column min-vh-100'>
        {/* <Header /> */}
        <BrowserRouter basename="/">
          <Suspense fallback={<SiteLoader />}>
              <Routes>
                <Route path="/" element={<ViewIndex />} >
                    <Route  exact="" path="" element={<Home />} />
                    <Route  exact="" path="about" element={<About />} />
                    <Route  exact="" path="contact_Us" element={<ContactUs />} />
                    <Route  exact="" path="privacy" element={<Privacy />} />
                    <Route  exact="" path="services" element={<Services />} />
                    <Route  exact="" path="privacy_policy" element={<Privacy />} />
                    <Route  exact="" path="terms_conditions" element={<TermsConditions />} />
                    <Route  exact="" path="cookie_policy" element={<CookiePolicy />} />
                </Route>
              </Routes>
            </Suspense>
          </BrowserRouter>
         
        </main>
        

    
  );
}

export default App;
